<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-09 16:50:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:04
 * @Description: 创建 修改 货品分类
 * @FilePath: \src\views\App\Iemma\NUCommodityClass.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="100px">
          <el-form-item label="分类名称：" prop="name" style="width: 45%">
            <el-input
              size="small"
              v-model="m.name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <div class="from-notes-box">
            <el-form-item
              label="描述："
              prop="notes"
              style="width: 90%; display: block"
            >
              <el-input
                size="small"
                v-model="m.notes"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateCommodityClass()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateCommodityClass()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import {
  createCommodityClass,
  updateCommodityClass,
  getCommodityClassInfoById,
} from "./api"; //页面专有接口
export default {
  data() {
    return {
      m: {
        name: "", //名称
        notes: "", //描述
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      kor_id: null, //组织ID
    };
  },
  methods: {
    // 打开
    open(id) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this.$nextTick(() => {
        if (id === 0) {
          //新建
          this.title = "添加货品分类";
          this.loading = false;
        } else {
          //修改
          this._GetCommodityInfo(id);
          this.title = "修改货品分类";
        }
      });
    },

    //添加货品分类
    _CreateCommodityClass() {
      var m = this.m;
      createCommodityClass({
        name: m.name,
        notes: m.notes,
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改货品分类
    _UpdateCommodityClass() {
      var m = this.m;
      updateCommodityClass({
        kcoc_id: this.id,
        name: m.name,
        notes: m.notes,
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取货品分类信息
    _GetCommodityInfo(kcoc_id) {
      getCommodityClassInfoById({ kcoc_id })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.name = d.name;
          m.notes = d.notes;
          this.loading = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
  created() {},
};
</script>
